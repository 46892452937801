import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  MenuItem,
  styled,
  Switch,
  Divider,
  IconButton,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";
import styles from "./index.module.css";
import useApi from "lib/useApi";
import OrgEarningsTable from "./OrgEarningsTable";
import OrgEarningsBarChart from "./OrgEarningsBarChart";
import RangePicker from "components/CustomUI/RangePicker";
import SelectorWithIcon from "components/CustomUI/SelectorWithIcon";
import CustomAutoComplete from "components/CustomUI/CustomAutoComplete";
import { useLocalStorage } from "contexts/LocalStorageContext";

export default function OrgRedemptionHistoryTable() {
  const { admin, allMembersId, isMobile } = useLocalStorage();
  const [selectedCrowd, setSelectedCrowd] = useState<{
    id: string;
    name: string;
  } | null>({
    id: "All",
    name: "All Crowds",
  });
  const [viewType, setViewType] = useState("table");
  const [type, setType] = useState("all");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchCriteriaUsers, setSearchCriteriaUsers] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [options, setOptions] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [inputValue, setInputValue] = useState("");

  const [date, setDate] = useState<Dayjs[]>([
    dayjs.utc().subtract(3, "month").startOf("month"),
    dayjs.utc().endOf("month"),
  ]);

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const orgId = localStorage()?.orgSelected;

  const [crowdsPage, setCrowdsPage] = useState(12);
  const [usersPage, setUsersPage] = useState(12);
  const [totalCrowds, setTotalCrowds] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const { data: allCrowds, mutate: mutateAllCrowds } = useApi(
    `/api/orgs/${orgId}/crowds?filter=${searchCriteria}&page=1&per_page=${crowdsPage}`,
    admin
  );

  const { data: crowdUsers, mutate: mutateUsers, isLoading: usersLoading } = useApi(
    `/api/orgs/${orgId}/crowds/${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }/everyone?filter=${searchCriteriaUsers}&page=1&per_page=${usersPage}`,
    admin
  );

  useEffect(() => {
    setTotalUsers(crowdUsers?.pagination?.total_items);
  }, [crowdUsers]);

// Handle scroll event for crowds
const handleCrowdsScroll = (event) => {
  const listboxNode = event.currentTarget;
  if (
    listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10 &&
    crowdsPage < totalCrowds
  ) {
    setCrowdsPage((prevPage) => prevPage + 10);
  }
};

// Handle scroll event for users
const handleUsersScroll = (event) => {
  const listboxNode = event.currentTarget;
  if (
    listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10
    && usersPage < totalUsers
  ) {
    setUsersPage((prevPage) => prevPage + 10);
  }
};


useEffect(() => {
  if (allCrowds === undefined) return;
  const fetchedOptions = allCrowds?.crowds.map((crowd) => ({
    id: crowd.id,
    name: crowd.name || "",
  }));
  setTotalCrowds(allCrowds?.pagination?.total_items);
  const all_crowds = [{ id: "All", name: "All Crowds" }];
  setOptions([...all_crowds, ...fetchedOptions]);
}, [allCrowds]);

  const clearSelectedUser = () => {
    setSearchCriteria("");
    setSelectedUser("");
  };

  const [downloadableChartData, setDownloadableChartData] = useState("");
  const [downloading, setDownloading] = useState(false);

  function exportToCSV(data) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Check if data is non-empty
    if (data.length === 0) return;

    // Header
    const headers = Object.keys(data[0]);
    csvContent += headers.join(",") + "\n";

    // Data
    data.forEach((item) => {
      const row = headers.map((header) => item[header]).join(",");
      csvContent += row + "\n";
    });

    // Trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${viewType === "table" ? "Org Table" : "Org Chart"} - ${new Date()}.csv`
    );
    document.body.appendChild(link);

    link.click();
    setDownloading(false);
  }

  const [downloadReady, setDownloadReady] = useState(false);

  return (
    <Paper
      className={styles.midTitles}
      sx={{ minHeight: isMobile ? "100%" : "500px" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack>Earnings</Stack>
          {downloadReady && viewType === "table" ? (
            <BlackTooltip title="Download CSV - Max 500 records">
              <IconButton
                onClick={() => {
                  setDownloading(true);
                }}
              >
                <FontAwesomeIcon icon="file-arrow-down" />
              </IconButton>
            </BlackTooltip>
          ) : viewType === "chart" ? (
            ""
          ) : (
            <CircularProgress size={20} />
          )}
          {viewType === "graph" ? (
            <BlackTooltip title="Download CSV">
              <IconButton onClick={() => exportToCSV(downloadableChartData)}>
                <FontAwesomeIcon icon="file-arrow-down" />
              </IconButton>
            </BlackTooltip>
          ) : null}
          {viewType === "chart" ? (
            <BlackTooltip title="Download CSV">
              <IconButton onClick={() => exportToCSV(downloadableChartData)}>
                <FontAwesomeIcon icon="file-arrow-down" />
              </IconButton>
            </BlackTooltip>
          ) : null}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <RangePicker date={date} setDate={setDate} />
          <Switcher
            checked={viewType === "table"}
            onChange={(e) => {
              setViewType(e.target.checked ? "table" : "chart");
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Stack>
      </Stack>
      <Stack
        width="100%"
        justifyContent="flex-end"
        mt={2}
        direction="row"
        spacing={3}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon="user-group-simple" />
          <Autocomplete
            sx={{ minWidth: 200 }}
            options={options || []}
            getOptionLabel={(option) => option.name}
            value={selectedCrowd}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              clearSelectedUser();
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              setSelectedCrowd(newValue);
              if (newValue) {
                setInputValue(newValue.name);
              }
            }}
            filterSelectedOptions
            ListboxProps={{
              onScroll: handleCrowdsScroll,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={(e) => {
                  setSearchCriteria(e.target.value);
                  setCrowdsPage(1); // Reset page to 1 for new search
                  setOptions([]);
                }}
                placeholder="Select Crowd..."
              />
            )}
          />
        </Stack>

        <CustomAutoComplete
          type="user"
          icon="user"
          placeholder="Select User..."
          setSearchCriteria={setSearchCriteriaUsers}
          mutate={mutateUsers}
          searchCriteria={searchCriteriaUsers}
          data={crowdUsers}
          setValue={setSelectedUser}
          value={selectedUser}
          isLoading={usersLoading}
          ListboxProps={{
            onScroll: handleUsersScroll,
          }}
        />

        <SelectorWithIcon
          value={type}
          onChange={(e) => setType(e.target.value)}
          icon="award"
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"gift"}>Gift</MenuItem>
          <MenuItem value={"reward"}>Reward</MenuItem>
        </SelectorWithIcon>
      </Stack>
      {viewType !== "chart" ? <Divider sx={{ mt: 1 }} /> : null}

      <Box
        sx={{
          position: "relative",
          display: "grid",
          width: "100%",
          height: viewType === "chart" ? "330px" : "350px",
        }}
      >
        {viewType === "table" ? (
          <OrgEarningsTable
            selectedUser={selectedUser}
            date={date}
            admin={admin}
            orgId={orgId}
            type={type}
            crowd={selectedCrowd?.id}
            downloading={downloading}
            setDownloading={setDownloading}
            setDownloadReady={setDownloadReady}
          />
        ) : (
          <OrgEarningsBarChart
            selectedUser={selectedUser}
            date={date}
            orgId={orgId}
            crowd={selectedCrowd?.id}
            type={type}
            setDownloadableChartData={setDownloadableChartData}
          />
        )}
      </Box>
    </Paper>
  );
}

const Switcher = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "#6F23FF",
        borderRadius: "50%",
        backgroundPositionX: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M48 264V160H232V264H48zm0 48H232V432H64c-8.8 0-16-7.2-16-16V312zM280 432V312H464V416c0 8.8-7.2 16-16 16H280zM464 264H280V160H464V264zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#6F23FF",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="10.5" viewBox="0 0 448 512"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
