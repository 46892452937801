import {
  Box,
  Paper,
  Stack,
  MenuItem,
  InputBase,
  styled,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import styles from "./index.module.css";
import DashboardChart from "components/chart/DashboardChart";
import { useEffect, useState } from "react";
import SelectorWithIcon from "components/CustomUI/SelectorWithIcon";
import useApi from "lib/useApi";
import RangePicker from "components/CustomUI/RangePicker";
import dayjs from "dayjs";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";
import { useLocalStorage } from "contexts/LocalStorageContext";

interface Props {}

export default function ParticipantEarningsGraph({}: Props) {
  const { orgId, isMobile } = useLocalStorage();
  const [date, setDate] = useState([
    dayjs.utc().subtract(3, "month").startOf("month"),
    dayjs.utc().endOf("month"),
  ]);
  const [crowdTypeSelected, setCrowdTypeSelected] = useState("all");
  const [type, setType] = useState("all");

  const [myChartData, setMyChartData] = useState({} as any);
  const { data: myChartDataRoot } = useApi(
    `/api/orgs/${orgId}/earnings/my_chart_data?start_date=${date[0]}&end_date=${
      date[1]
    }${crowdTypeSelected !== "all" ? `&crowd_id=${crowdTypeSelected}` : ""}${
      type !== "all" ? `&earning_type=${type} ` : ""
    }`,
    true,
    { immutable: true }
  );

  const { data: myEarnings, mutate: mutateMyEarnings } = useApi(
    `/api/orgs/${orgId}/members/my_wallet_history.csv?start_date=${
      date[0]
    }&end_date=${date[1]}${
      crowdTypeSelected !== "all" ? `&crowd_id=${crowdTypeSelected}` : ""
    }${type !== "all" ? `&earning_type=${type} ` : ""}`,
    true
  );

  const [myCrowds, setMyCrowds] = useState([]);
  const { data: crowds } = useApi(`/api/orgs/${orgId}/crowds/my_crowds`, true, {
    immutable: true,
  });

  const downloadMyEarningsReport = async () => {
    let data = await mutateMyEarnings();
    if (data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${dayjs.utc()}-My_Earnings.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    if (crowds) {
      const myCrowds = crowds.crowds?.map((crowd) => ({
        id: crowd.id,
        name: crowd.name,
      }));
      setMyCrowds(myCrowds);
    }
  }, [crowds]);

  useEffect(() => {
    if (myChartDataRoot) {
      setMyChartData(myChartDataRoot);
    }
  }, [myChartDataRoot]);

  const handleChangeTypesCrowds = (event: SelectChangeEvent) => {
    setCrowdTypeSelected(event.target.value);
  };

  return (
    <Paper
      className={styles.midTitles}
      sx={{ minHeight: isMobile ? "100%" : "450px" }}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <Stack>Earnings</Stack>
          <BlackTooltip title="Download CSV">
            <IconButton onClick={() => downloadMyEarningsReport()}>
              <FontAwesomeIcon icon="file-arrow-down" />
            </IconButton>
          </BlackTooltip>
        </Stack>
      </Stack>

      <Stack
        width="100%"
        justifyContent="flex-end"
        mt={2}
        direction="row"
        spacing={3}
        flexWrap={"wrap"}
      >
        <SelectorWithIcon
          value={crowdTypeSelected}
          onChange={handleChangeTypesCrowds}
          icon="user-group-simple"
        >
          <MenuItem value={"all"}>All</MenuItem>
          {myCrowds?.map((type: any) => (
            <MenuItem key={type?.id} value={type?.id}>
              {type?.name}
            </MenuItem>
          ))}
        </SelectorWithIcon>

        <SelectorWithIcon
          value={type}
          onChange={(e) => setType(e.target.value)}
          icon="award"
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"gift"}>Gift</MenuItem>
          <MenuItem value={"reward"}>Reward</MenuItem>
        </SelectorWithIcon>

        <RangePicker date={date} setDate={setDate} />
      </Stack>
      <Box
        sx={{
          position: "relative",
          height: isMobile ? "450px" : "310px",
          pt: 2,
          pb: isMobile ? 2 : 0,
        }}
      >
        <DashboardChart data={myChartData} />
      </Box>
    </Paper>
  );
}
